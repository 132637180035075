import Vue from 'vue'
import './assets/css/normalize.css'
import './assets/css/global.scss'
// import './assets/font/font.scss'
import 'vue-area-linkage/dist/index.css';
import AreaLinkage from 'vue-area-linkage';
Vue.use(AreaLinkage)

import App from './App.vue'
import router from './router'

//导入全局基础组件和变量
import '@/utils/base.js'

//全局变量
import global from '@/utils/global.js';

Vue.prototype.$global = global

Vue.config.productionTip = false

new Vue({
  data: {
    global
  },
  router,
  render: h => h(App)
}).$mount('#app')
