import router,{resetRouter} from "@/router"

const global= {
  //判断是否外链
  isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
  },
  
  config: {
    web_title: '',
    bank: {},
    system: {},
    kefu: {}
  },

  adminUser: {}, //用户信息

  ApiList: [],
  
  MenuList: [],

  token: localStorage.getItem('adminToken'),

  // 设置token
  setToken(token) {
    window.localStorage.setItem('adminToken', token)
    global.token = token
  },

  allSliceFlag: false, //是否静音

  autoPlayFlag: false, //是否自动播放过一次

  toastShow: false,
  toastMsg: '',

  ent_id: null, //企业ID

  //退出登录
  logout() {
    localStorage.removeItem('adminToken')
    global.token = null
    global.MenuList = []
    global.ApiList = []
    resetRouter()
    router.push('/login')
  },
}

export default global