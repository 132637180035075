import Vue from 'vue'
import VueRouter from 'vue-router'
import constRouters from './constRouters'
//进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

//初始化路由函数
const createRouter = () => new VueRouter({
  base: process.env.BASE_URL,
  routes: constRouters
})

//创建路由
const router = createRouter()

router.beforeEach((to, from, next) => {
  //设置进度条
  NProgress.start()
  // //设置title
  // let title = '后台管理系统';
  // if (to.name) {
  //   title = `${to.name} - ${title}`
  // }
  // document.title = title
  next()
  NProgress.done()
})


//重置路由
export function resetRouter() {
  router.matcher = createRouter().matcher
}

export default router
