import Vue from 'vue'

//Element 组件
import Element from 'element-ui'
import './element-variables.scss'
Vue.use(Element)

import '@/assets/iconfont/iconfont.css'

//svg组件
import SvgIcon from '@/components/SvgIcon'
// 注册到全局
Vue.component(SvgIcon.name, SvgIcon)

//复制黏贴组件  this.$copyText(this.message).then(()=>{console.log('复制成功')},(e)=>{console.log(e)})
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// import AudioPlay from '@/components/AudioPlay.vue';

// Vue.component(AudioPlay.name, AudioPlay)

// Vue.directive('countRunning', {
//   bind(el) {
//       var timer = null;
//       const targetDomCount = el.getAttribute('data-target'); //data-target为目标值
//       let nowCount = parseInt(el.innerHTML); //当前页面显示值
//       timer = setInterval(() => {
//           if (nowCount < targetDomCount) {
//               nowCount++;
//           } else {
//               clearInterval(timer);
//               timer = null;
//           }
//           el.innerHTML = nowCount;
//       }, 10);
//   }
// })


//websocket链接
import ws from '@/utils/ws.js';

Vue.prototype.$ws = ws

// Math.formatFloat (5.39 - 5.34, 2)
// f 计算表达式
// digit 小数位数
// 处理js小数精度丢失的bug
Math.formatFloat = function (f, digit) {
  var m = Math.pow(10, digit);
  return Math.round(f * m) / m;
}