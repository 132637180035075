export default [
  // {
  //   path: '/',
  //   name: 'layout',
  //   title: 'layout',
  //   component: () => import('@/views/layout/Layout.vue')
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import( '@/views/login/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( '@/views/register/Register.vue')
  },
  {
    path: '/refresh',
    name: '空路由',
    component: () => import( '@/views/refresh/Refresh.vue')
  },
  {
      path: '*',
      component: () => import('@/views/all/All.vue')
  }
]